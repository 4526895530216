import React from "react";
import { createBrowserRouter } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Blog from "./pages/Blog";
import Home from "./pages/Home";
import CarLoan from "./pages/CarLoan";
import PaydayLoan from "./pages/PaydayLoan";
import BusinessLoan from "./pages/BusinessLoan";
import InsuranceServices from "./pages/InsuranceServices";
import MedicalServices from "./pages/MedicalServices";
import StudyLoan from "./pages/StudyLoan";
import PersonalLoan from "./pages/PersonalLoan";
import RefinancingLoan from "./pages/RefinancingLoan";
import Stock from "./pages/Stock";
import FaqPage from "./pages/FaqPage";
import LoyaltyProgram from "./pages/LoyaltyProgram";
import CreditForPurchase from "./pages/CreditForPurchase";
import NeedMoney from "./pages/StockPages/NeedMoney";
import TestStudentLoan from "./pages/TestStudentLoan";
import MoneyBeforeSalary from "./pages/StockPages/MoneyBeforeSalary";

const routers = createBrowserRouter([
    {
        path: "/",
        element: <PaydayLoan />
    },
    {
        path: "/home",
        element: <Home />
    },
    {
        path: "/about-us",
        element: <AboutUs />
    },
    // {
    //     path: "/blog",
    //     element: <Blog />
    // },
    {
        path: "/car-loan",
        element: <CarLoan />
    },
    {
        path: "/credit-for-purchase",
        element: <CreditForPurchase />
    },
    // {
    //     path: "/business-loan",
    //     element: <BusinessLoan />
    // },
    // {
    //     path: "/insurance-services",
    //     element: <InsuranceServices />
    // },
    // {
    //     path: "/medical-services",
    //     element: <MedicalServices />
    // },
    // {
    //     path: "/study-loan",
    //     element: <StudyLoan />
    // },
    // {
    //     path: "/personal-loan",
    //     element: <PersonalLoan />
    // },
    // {
    //     path: "/refinancing-loan",
    //     element: <RefinancingLoan />
    // },
    {
        path: "/stock",
        element: <Stock />
    },
    {
        path: "/stock/need-money",
        element: <NeedMoney />
    },
    {
        path: "/stock/money-before-salary",
        element: <MoneyBeforeSalary />
    },
    {
        path: "/faq",
        element: <FaqPage />
    },
    {
        path: "/loyalty-program",
        element: <LoyaltyProgram />
    },
    {
        path: "/testing-student-loan",
        element: <TestStudentLoan />
    },
    { path: '*', element: <PaydayLoan /> },
]);

export default routers