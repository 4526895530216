import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const portal = document.getElementById("modal-root")

const Portal = ({ children, onClose }) => {

    const handleCloseModal = () => {
        portal.classList.remove("modal__menu");
        onClose();
    }

    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (ref.current && !ref?.current?.contains(e.target)) {
                // portal.classList.remove("modal__menu");
                // onClose()
            }
        }
        document.addEventListener("click", checkIfClickedOutside)
        return () => {
            document.removeEventListener("click", checkIfClickedOutside)
        }
    }, [onClose]);

    useEffect(() => {
        document.body.style.overflow = "hidden"
        return () => {
            document.body.style.overflow = "auto"
        }
    }, []);

    useEffect(() => {
        portal.classList.add("modal__menu");
    }, []);

    return ReactDOM.createPortal(
        <div className="modal__content" ref={ref}>
            <div className="modal__close" onClick={handleCloseModal} />
            {children}
        </div>,
        portal)
}

export default Portal