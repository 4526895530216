import React from 'react';
import Header from "../../components/Header/Header";
import video1 from "../../assets/video/1.mp4";
import ScrollLink from "../../lib/helpers/ScrollLink";
import blogImg2 from "../../assets/blog_img_2.png";
import blogImg3 from "../../assets/blog_img_3.png";
import blogImg4 from "../../assets/blog_img_4.png";
import blogImg5 from "../../assets/blog_img_5.png";
import blogImg6 from "../../assets/blog_img_6.png";
import blogImg1 from "../../assets/blog_img_1.png";
import TermsUsingCredit from "../../components/TermsUsingCredit/TermsUsingCredit";
import Footer from "../../components/Footer/Footer";

const MoneyBeforeSalary = () => {
    return (
        <div className="stock">
            <div className="top_background" style={{ backgroundImage: 'none' }}>
                <div className="container">
                    <Header activePageName="stock" />
                    <div className="blog__wrapper">
                        <div className="title">
                            Наші переваги
                            Гроші онлайн – до зарплати не чекай!
                        </div>
                        <div className="blog__item" style={{ width: '100%' }}>
                            <div className="blog__desc">
                                Життєві обставини інколи диктують свої умови, коли гроші необхідні тут і зараз, і чекати до зарплати – не варіант.
                                <br />
                                <br />
                                Пропонуємо оптимальне рішення – оформити мікрокредит на 3000 - 6000 грн в Groshi 24/7:
                                <br />
                                - миттєво;
                                <br />
                                - онлайн;
                                <br />
                                - без візиту до офісу.
                                <br />
                                <br />
                                Ми гарантуємо:
                                <br />
                                - швидкість: гроші будуть у вас на картці через 5 хвилин завдяки автоматизованій обробці заявок;
                                <br />
                                - безпеку та конфіденційність: вам не потрібні поручителі, а ваші персональні дані захищені міжнародними сертифікатами безпеки;
                                <br />
                                - лояльність: можливість пролонгації кредиту, вигідні дисконтні пропозиції та індивідуальний підхід до кожного клієнта.
                                <br />
                                <br />
                                Кредит до зарплати може отримати будь-яка особа віком від 18 до 60 років, яка має банківську картку, мобільний телефон чи комп'ютер з виходом в Інтернет, паспорт та ІПН.
                                <br />
                                <br />
                                Докладніше на сайті  <ScrollLink to="/">https://groshi247.com/</ScrollLink>, переходьте, натискайте кнопку “Отримати кредит”, заповнюйте анкету та отримуйте гроші протягом 5 хвилин на картку.
                                <br />
                                <br />
                                Groshi 24/7 – ваш цілодобовий і надійний помічник в світі фінансів.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default MoneyBeforeSalary;