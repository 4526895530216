import React from "react";
import { RouterProvider } from "react-router-dom";
import Favicon from "react-favicon";
import routers from "./routers";
import "./App.scss";
import faicon_img from "./assets/favicon/logo.ico";
import TawkToChat from "./components/TawkToChat/TawkToChat";
import PushNotificationModal from "./components/PushNotificationModal/PushNotificationModal";

function App() {
  return (
      <>
        <Favicon url={faicon_img} />
        <RouterProvider router={routers} />
        <TawkToChat />
        <PushNotificationModal />
      </>
  )
}

export default App;
