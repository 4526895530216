export const LANDING_URL = process.env.REACT_APP_LANDING_URL ? process.env.REACT_APP_LANDING_URL : '';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : '';
export const SALARY_LOAN_ID = process.env.REACT_APP_SALARY_LOAN_ID ? process.env.REACT_APP_SALARY_LOAN_ID : '';
export const SALARY_LOAN_PERIOD = process.env.REACT_APP_SALARY_LOAN_PERIOD ? process.env.REACT_APP_SALARY_LOAN_PERIOD : '';
export const TAWK_ACCOUNT_KEY = process.env.REACT_APP_TAWK_ACCOUNT_KEY ? process.env.REACT_APP_TAWK_ACCOUNT_KEY : '';
export const TAWK_ID = process.env.REACT_APP_TAWK_ID ? process.env.REACT_APP_TAWK_ID : '';
export const REACT_APP_SEND_EMAIL_TOKEN = process.env.REACT_APP_SEND_EMAIL_TOKEN ? process.env.REACT_APP_SEND_EMAIL_TOKEN : '';

export const CREDIT_POLICIES_GET = `${BACKEND_URL}/api/lender/credit_policies/`;  // get
export const PAYMENT_SCHEDULE_CALCULATE_POST = `${BACKEND_URL}/api/payment-schedule/calculate/`;  // post
export const SHOP_REQUEST_POST = `${BACKEND_URL}/api/api_integration/shop_request/`;  // post