import { REACT_APP_SEND_EMAIL_TOKEN } from './../../const/api'

export const sendEmail = async (data) => {
    if (!window.Email) return console.error('Email service not available');
    
    const body = getEmailBody(data);

    try {
        return await window.Email.send({
            SecureToken: REACT_APP_SEND_EMAIL_TOKEN,
            To: "info@groshi247.com",
            From: "no-replay@groshi247.com",
            Subject: `[Заявка через сайт] від ${data.email}`,
            Body: body,
        });
    } catch (error) {
        console.error('Ошибка при отправке email:', error);
        return error;
    }
};

const getEmailBody = (data) => {
    return `
    <html>
            <body>
                <h2>Заявка через сайт</h2>
                <p><strong>Ім'я:</strong> ${data.name}</p>
                <p><strong>Телефон:</strong> ${data.phone}</p>
                <p><strong>Електронна пошта:</strong> ${data.email}</p>
                <p><strong>Згода з умовами:</strong> ${data.agree ? 'Так' : 'Ні'}</p>
                <p><strong>Згода на рекламу:</strong> ${data.agreeAds ? 'Так' : 'Ні'}</p>
            </body>
            </html>
            `
} 