import { Slider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import TermsUsingCredit from "../components/TermsUsingCredit/TermsUsingCredit";
import WarningCredit from "../components/WarningCredit/WarningCredit";
import FAQ from "../components/FAQ/FAQ";
import toFixNumber from "../lib/helpers/toFixNumber";
import Button from "../components/Common/Button";
import useCalculate from "../lib/hooks/useCalculate";
import fastestDeliveryIcon from "../assets/fastest-delivery.png";
import cardBlackIcon from "../assets/card_black.png";
import percentIcon from "../assets/percent.png";
import phoneIcon from "../assets/phone.png";
import shieldIcon from "../assets/shield.png";
import smileHappyIcon from "../assets/smile_happy.png";
import timeIsMoneyIcon from "../assets/time_is_money.png";
import formsIcon from "../assets/forms.png";
import documentAccessIcon from "../assets/document_access.png";
import registrationIcon from "../assets/registration.png";
import bankIcon from "../assets/bank.png";
import "swiper/css";
import informationAboutTheServiceAdditionFile from "../files/Інформація_про_послугу_з_надання_мікрокредитів.pdf";
import Input from "@mui/material/Input";
import moment from "moment";
import ApplicationForm from "../components/ApplicationForm/ApplicationForm";

const TestStudentLoan = () => {
    const {
        total,
        principal,
        interest,
        creditPolicyInterest,
        loanTerm,
        setLoanTerm,
        setLoanAmount,
        creditPolicies,
        createShopRequest,
        promocode,
        setPromocode,
        password,
        setPassword,
        sendPromoCodeLoan
    } = useCalculate({
        creditProductId: 7,
        needPassword: true
    });

    return (
        <div className="payday_loan">
            <div className="top_background__payday">
                <div className="container">
                    <Header activePageName="/" />
                    <div className="payday_loan__wrapper">
                        <div className="payday_loan__left">
                            <div className="title">Кредит до зарплати</div>
                            <div className="desc">Онлайн кредит на картку за 5 хвилин</div>
                        </div>
                        <div className="car_loan__calculate">
                            <div className="car_loan__calculate_left loan_calculate">
                                <div className="loan_calculate__slider">
                                    <div className="loan_calculate__slider_title">Сума кредиту, грн.</div>
                                    <Slider
                                        defaultValue={creditPolicies?.loan_amount_limit_from ? creditPolicies?.loan_amount_limit_from : 0}
                                        aria-label="Default"
                                        valueLabelDisplay="auto"
                                        min={creditPolicies?.loan_amount_limit_from ? creditPolicies?.loan_amount_limit_from : 0}
                                        max={creditPolicies?.loan_amount_limit_to ? creditPolicies?.loan_amount_limit_to : 0}
                                        onChange={(e) => setLoanAmount(e.target.value)}
                                        step={100}
                                    />
                                    <div className="loan_calculate__slider_block">
                                        <div className="loan_calculate__slider_desc">
                                            {creditPolicies?.loan_amount_limit_from
                                                ? creditPolicies?.loan_amount_limit_from
                                                : 0
                                            } ₴
                                        </div>
                                        <div className="loan_calculate__slider_desc">
                                            {creditPolicies?.loan_amount_limit_to
                                                ? creditPolicies?.loan_amount_limit_to
                                                : 0
                                            } ₴
                                        </div>
                                    </div>
                                </div>
                                <div className="loan_calculate__slider">
                                    <div className="loan_calculate__slider_title">Строк кредиту, днів</div>
                                    <Slider
                                        defaultValue={creditPolicies?.loan_term_limit_from ? creditPolicies?.loan_term_limit_from : 0}
                                        aria-label="Default"
                                        valueLabelDisplay="auto"
                                        min={creditPolicies?.loan_term_limit_from ? creditPolicies?.loan_term_limit_from : 0}
                                        max={creditPolicies?.loan_term_limit_to ? creditPolicies?.loan_term_limit_to : 0}
                                        onChange={(e) => setLoanTerm(e.target.value)}
                                    />
                                    <div className="loan_calculate__slider_block">
                                        <div className="loan_calculate__slider_desc">
                                            {creditPolicies?.loan_term_limit_from
                                                ? creditPolicies?.loan_term_limit_from
                                                : 0
                                            } дн.
                                        </div>
                                        <div className="loan_calculate__slider_desc">
                                            {creditPolicies?.loan_term_limit_to
                                                ? creditPolicies?.loan_term_limit_to
                                                : 0
                                            } дн.
                                        </div>
                                    </div>
                                </div>
                                <div className="loan_calculate__promocode">
                                    <div className="loan_calculate__slider_title">Є промокод?</div>
                                    <div className="loan_calculate__promocode_block">
                                        <Input
                                            size="small"
                                            placeholder="Введіть промокод"
                                            id="standard-basic"
                                            variant="outlined"
                                            value={promocode}
                                            onChange={(e) => setPromocode(e.target.value)}
                                            sx={{
                                                '& .MuiInputBase-input::placeholder': {
                                                    color: 'black',
                                                    opacity: 1
                                                },
                                                '&:before': {
                                                    borderBottomColor: promocode ? '#f07c93' : '#000'
                                                },
                                                '&:hover:not(.Mui-disabled):before': {
                                                    borderBottomColor: '#0c6a6c'
                                                },
                                                '&.Mui-focused:after': {
                                                    borderBottomColor: '#f07c93'
                                                }
                                            }}
                                        />
                                        <button
                                            className="btn btn__green"
                                            onClick={sendPromoCodeLoan}
                                            disabled={password !== 'Hs212Tp22'}
                                        >
                                            Застосувати
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="car_loan__calculate_right loan_calculate__right">
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Загальні витрати за кредитом, грн.</div>
                                    <div className="loan_calculate__right__number">{toFixNumber(principal)} грн.</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Сума платежу за розрахунковий період, грн.</div>
                                    <div className="loan_calculate__right__number">{toFixNumber(interest)} грн.</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Загальна вартість кредиту, грн.</div>
                                    <div className="loan_calculate__right__number">{toFixNumber(total)} грн.</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Реальна річна процентна ставка,%</div>
                                    <div className="loan_calculate__right__number">
                                        {creditPolicyInterest ? toFixNumber(creditPolicyInterest * 100) : '---'} %
                                    </div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Дата оплати</div>
                                    <div className="loan_calculate__right__number">
                                        {loanTerm ? moment().add(loanTerm, 'days').format("DD.MM.YYYY") : '---'}
                                    </div>
                                </div>
                                <Button onClick={createShopRequest} pink disabled={password !== 'Hs212Tp22'}>
                                    Оформити кредит
                                </Button>
                            </div>
                            <div className="car_loan__calculate_info">
                                <div
                                    className="loan_calculate__promocode_block"
                                    style={{ marginBottom: '16px', width: '100%' }}
                                >
                                    <Input
                                        size="small"
                                        placeholder="Введіть пароль"
                                        id="test-password"
                                        variant="outlined"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        sx={{
                                            '& .MuiInputBase-input::placeholder': {
                                                color: 'black',
                                                opacity: 1
                                            },
                                            '&:before': {
                                                borderBottomColor: password ? '#f07c93' : '#000'
                                            },
                                            '&:hover:not(.Mui-disabled):before': {
                                                borderBottomColor: '#0c6a6c'
                                            },
                                            '&.Mui-focused:after': {
                                                borderBottomColor: '#f07c93'
                                            },
                                            width: '100%'
                                        }}
                                    />
                                </div>
                                {/* <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'red',
                                        fontSize: '18px',
                                        fontWeight: 700,
                                        textDecorations: 'uppercase',
                                        marginBottom: '10px',
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    Кредити тимчасово не видаються. Орієнтований час відновлення 15.00
                                </div> */}
                                <Link to={informationAboutTheServiceAdditionFile} className="car_loan__calculate_info__link" target="_blank">
                                    Інформація про істотні характеристики послуги з надання мікрокредитів
                                </Link>
                                Фінансова установа розміщує на сторінці з інформацією про послугу з надання споживчого кредиту приклади результатів розрахунків калькулятора, до яких включає інформацію про всі припущення, використані для розрахунку загальних витрат за послугою з надання споживчого кредиту
                                {/* <div style={{ display: "flex", alignItems: 'center', gap: "10px", marginTop: "10px" }}>
                                    <img src={viberIcon} alt="viber" style={{ width: "36px", cursor: "pointer" }} />
                                    <img src={kyivstarIcon} alt="kyivstar" style={{ width: "36px", cursor: "pointer" }} />
                                    <img src={lifecellIcon} alt="lifecel" style={{ height: "36px", cursor: "pointer" }} />
                                    <img src={phoneCallIcon} alt="phone call" style={{ width: "36px", cursor: "pointer" }} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="get_loan payday_loan_from_us">
                <div className="container">
                    <div className="get_loan__list">
                        <div className="get_loan__item">
                            <img src={fastestDeliveryIcon} alt="icon 1" />
                            <div className="get_loan__item_text">Подай  заявку на позику, і  Гроші 24/7 будуть на твоій картці вже через 5 хвилин.</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={phoneIcon} alt="icon 2" />
                            <div className="get_loan__item_text">100%<br /> on-line</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={timeIsMoneyIcon} alt="icon 3" />
                            <div className="get_loan__item_text">Кредит можна<br /> пролонгувати</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={percentIcon} alt="icon 4" />
                            <div className="get_loan__item_text">Діє програма<br /> лояльності</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={shieldIcon} alt="icon 5" />
                            <div className="get_loan__item_text">Завдяки автоматизованій<br /> обробці та<br /> персоналізованому підходу.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="payday_loan_how_get">
                <div className="container">
                    <div className="title">Як отримати кредит</div>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        className="payday_loan_how_get__swiper"
                        breakpoints={{
                            500: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            550: {
                                slidesPerView: 3,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 5,
                                spaceBetween: 50,
                            },
                        }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>
                            <div className="get_loan__item payday_loan_how_get__item">
                                <div className="payday_loan_how_get__img">
                                    <img src={phoneIcon} alt="phone icon" />
                                </div>
                                <div className="payday_loan_how_get__text">
                                    Оформити кредит
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item payday_loan_how_get__item">
                                <div className="payday_loan_how_get__img">
                                    <img src={formsIcon} alt="form icon" />
                                </div>
                                <div className="payday_loan_how_get__text">
                                    Заповнити<br /> анкету
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item payday_loan_how_get__item">
                                <div className="payday_loan_how_get__img">
                                    <img src={documentAccessIcon} alt="documentAccess icon" />
                                </div>
                                <div className="payday_loan_how_get__text">
                                    Отримати<br /> рішення
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item payday_loan_how_get__item">
                                <div className="payday_loan_how_get__img">
                                    <img src={registrationIcon} alt="registration icon" />
                                </div>
                                <div className="payday_loan_how_get__text">
                                    Підписати договір<br /> кредиту та поруки
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item payday_loan_how_get__item">
                                <div className="payday_loan_how_get__img">
                                    <img src={smileHappyIcon} alt="smileHappy icon" />
                                </div>
                                <div className="payday_loan_how_get__text">
                                    Отримати<br /> кошти
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="payday_loan_how_get__list">
                        <div className="payday_loan_how_get__item">
                            <div className="payday_loan_how_get__img">
                                <img src={phoneIcon} alt="phone icon" />
                            </div>
                            <div className="payday_loan_how_get__text">
                                Оформити кредит
                            </div>
                        </div>
                        <div className="payday_loan_how_get__item">
                            <div className="payday_loan_how_get__img">
                                <img src={formsIcon} alt="form icon" />
                            </div>
                            <div className="payday_loan_how_get__text">
                                Заповнити<br /> анкету
                            </div>
                        </div>
                        <div className="payday_loan_how_get__item">
                            <div className="payday_loan_how_get__img">
                                <img src={documentAccessIcon} alt="documentAccess icon" />
                            </div>
                            <div className="payday_loan_how_get__text">
                                Отримати<br /> рішення
                            </div>
                        </div>
                        <div className="payday_loan_how_get__item">
                            <div className="payday_loan_how_get__img">
                                <img src={registrationIcon} alt="registration icon" />
                            </div>
                            <div className="payday_loan_how_get__text">
                                Підписати договір<br /> кредиту та поруки
                            </div>
                        </div>
                        <div className="payday_loan_how_get__item">
                            <div className="payday_loan_how_get__img">
                                <img src={smileHappyIcon} alt="smileHappy icon" />
                            </div>
                            <div className="payday_loan_how_get__text">
                                Отримати<br /> кошти
                            </div>
                        </div>
                    </div>
                    <div className="payday_loan_how_get__btn_block">
                        <button className="btn btn__pink">
                            <Link
                                to="https://cab.groshi247.com/signup"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Оформити кредит
                            </Link>
                        </button>
                    </div>
                    <div className="payday_loan_how_get__video">
                        <iframe width="565" height="340" src="https://www.youtube.com/embed/7u4g9ByyBX8"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen />
                    </div>
                </div>
            </div>
            <ApplicationForm />
            <div className="payday_loan_how_to_repay">
                <div className="container">
                    <div className="car_loan_how_get_loan__title title">Як погасити кредит</div>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        className="payday_loan_how_to_repay__swiper"
                        breakpoints={{
                            500: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            }
                        }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>
                            <div className="get_loan__item car_loan_how_get_loan__item">
                                <img src={phoneIcon} alt="icon 1" />
                                <div className="get_loan__item_text">Вибрати<br /> автомобіль</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item car_loan_how_get_loan__item">
                                <img src={bankIcon} alt="icon 2" />
                                <div className="get_loan__item_text">Подати on-line<br /> заявку на кредит</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item car_loan_how_get_loan__item">
                                <img src={cardBlackIcon} alt="icon 3" />
                                <div className="get_loan__item_text">Оформити<br /> кредит</div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="car_loan_how_get_loan__list get_loan__list">
                        <div className="get_loan__item car_loan_how_get_loan__item">
                            <img src={phoneIcon} alt="icon 1" />
                            <div className="get_loan__item_text">Оплата з карти в<br /> особистому кабінеті</div>
                        </div>
                        <div className="get_loan__item car_loan_how_get_loan__item">
                            <img src={bankIcon} alt="icon 2" />
                            <div className="get_loan__item_text">Оплата в банку з рахунку<br /> на рахунок</div>
                        </div>
                        <div className="get_loan__item car_loan_how_get_loan__item">
                            <img src={cardBlackIcon} alt="icon 3" />
                            <div className="get_loan__item_text">Оплата в терміналах<br /> самообслуговування</div>
                        </div>
                    </div>
                </div>
            </div>
            <WarningCredit />
            <TermsUsingCredit />
            <FAQ />
            <Footer />
        </div>
    );
};

export default TestStudentLoan;