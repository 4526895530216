import React, { useState } from "react";
import { Link } from "react-router-dom";
import Portal from "../Portal";
import { BACKEND_URL } from "../../const/api";
import logo from "../../assets/logo_copy1.png";
import instagram from "../../assets/social_instagram_pink.png";
import facebook from "../../assets/social_facebook_pink.png";
import whatsapp from "../../assets/social_whatsapp_pink.png";
import telegram from "../../assets/social_telegram_pink.png";
import "./Header.scss";

const Header = ({ activePageName }) => {
    const [activeMenu, setActiveMenu] = useState(false);
    const [activeCreditProduct, setActiveCreditProduct] = useState(false);

    const handleOpenMenuModal = () => setActiveMenu(prev => !prev);
    const handleToggleMenuCreditProductModal = () => setActiveCreditProduct(prev => !prev);

    const removeModalClass = () => {
        const portal = document.getElementById("modal-root");
        portal.classList.remove("modal__menu");
        setActiveMenu(false);
    }

    return (
        <header className="header">
            <div className="header__block">
                <button className={`header__item_menu ${activeMenu ? 'active' : ''}`} onClick={handleOpenMenuModal}>
                    <div className="header__item_menu__line" />
                    <div className="header__item_menu__line" />
                    <div className="header__item_menu__line" />
                </button>
                <div className="header__item">
                    <Link className="header__logo" to='/home'>
                        <img src={logo} alt="logo" style={{ maxWidth: '140px' }} />
                        <p className="header__logo_text">one stop shop for your finance</p>
                    </Link>
                    <nav>
                        <ul className="header__menu">
                            <li>
                                <Link to="/" className={`${activePageName === '/' ? 'active' : ''}`}>
                                    Кредитні продукти
                                </Link>
                                <ul className="modal__list">
                                    <li className="modal__item">
                                        <Link to="/" className={`${activePageName === '/' ? 'active' : ''}`}>
                                            Кредит до зарплати
                                        </Link>
                                    </li>
                                    {/*<li className="modal__item">*/}
                                    {/*    <Link to="/personal-loan" className={`${activePageName === 'personal-loan' ? 'active' : ''}`}>*/}
                                    {/*        Персональний кредит*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="modal__item">*/}
                                    {/*    <Link to="/car-loan" className={`${activePageName === 'car-loan' ? 'active' : ''}`}>*/}
                                    {/*        Автокредит*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="modal__item">
                                        <Link to="/business-loan" className={`${activePageName === 'business-loan' ? 'active' : ''}`}>
                                            Кредити бізнесу
                                        </Link>
                                    </li>*/}
                                    {/*<li className="modal__item">*/}
                                    {/*    <Link to="/refinancing-loan" className={`${activePageName === 'refinancing-loan' ? 'active' : ''}`}>*/}
                                    {/*        Рефінансування кредиту*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="modal__item">*/}
                                    {/*    <Link to="/study-loan" className={`${activePageName === 'study-loan' ? 'active' : ''}`}>*/}
                                    {/*        Кредити на навчання*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="modal__item">*/}
                                    {/*    <Link to="/medical-services" className={`${activePageName === 'medical-services' ? 'active' : ''}`}>*/}
                                    {/*        Кредит на медичні послуги*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li className="modal__item">*/}
                                    {/*    <Link to="/insurance-services" className={`${activePageName === 'insurance-services' ? 'active' : ''}`}>*/}
                                    {/*        Страхові послуги*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                </ul>
                            </li>
                            <li>
                                <Link to="/loyalty-program" className={`${activePageName === 'loyalty-program' ? 'active' : ''}`}>
                                    Програма лояльності
                                </Link>
                            </li>
                            <li>
                                <Link to="/stock" className={`${activePageName === 'stock' ? 'active' : ''}`}>
                                Акції
                                </Link>
                            </li>
                            <li>
                                <Link to="/faq" className={`${activePageName === 'faq' ? 'active' : ''}`}>
                                    Питання та відповіді
                                </Link>
                            </li>
                            <li>
                                <Link to="/about-us" className={`${activePageName === 'about-us' ? 'active' : ''}`}>
                                    Про нас
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/blog" className={`${activePageName === 'blog' ? 'active' : ''}`}>
                                    Блог
                                </Link>
                            </li> */}
                        </ul>
                    </nav>
                </div>
                <div className="header__login_block">
                    <button className="btn btn__border btn__width_136">
                        <Link
                            to={`${BACKEND_URL}/signup`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Оформити кредит
                        </Link>
                    </button>
                    <button className="btn btn__pink btn__width_106">
                        <Link to={`${BACKEND_URL}/signup`}>ОПЛАТИТИ</Link>
                    </button>
                    <Link
                        to={`${BACKEND_URL}/login`}
                        className="header__cabinet"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Мій кабінет
                    </Link>
                </div>
            </div>
            <div className="header__phone_block">
                <a href="tel:+380444553538">(044) 455-35-38</a>
            </div>
            {activeMenu && <Portal children={<>
                <Link className="header__logo" to='/'>
                    <img src={logo} alt="logo" style={{ maxWidth: '140px' }} />
                    <p className="header__logo_text">one stop shop for your finance</p>
                </Link>
                <Link
                    to={`${BACKEND_URL}/login`}
                    className="btn btn__border"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Мій кабінет
                </Link>
                <ul className="modal__list">
                    <li className="modal__item">
                        <button onClick={handleToggleMenuCreditProductModal} className={`${activePageName === '/' ? 'active' : ''}`}>Кредитні продукти</button>
                        {activeCreditProduct && (
                            <ul>
                                <li className="modal__item" onClick={removeModalClass} style={{ borderTop: '1px solid #ededed', marginTop: 10 }}>
                                    <Link to="/" className={`${activePageName === '/' ? 'active' : ''}`}>
                                        Кредит до зарплати
                                    </Link>
                                </li>
                                {/*<li className="modal__item" onClick={removeModalClass}>*/}
                                {/*    <Link to="/personal-loan" className={`${activePageName === 'personal-loan' ? 'active' : ''}`}>*/}
                                {/*        Персональний кредит*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="modal__item" onClick={removeModalClass}>*/}
                                {/*    <Link to="/car-loan" className={`${activePageName === 'car-loan' ? 'active' : ''}`}>*/}
                                {/*        Автокредит*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="modal__item" onClick={removeModalClass}>
                                    <Link to="/business-loan" className={`${activePageName === 'business-loan' ? 'active' : ''}`}>
                                        Кредити бізнесу
                                    </Link>
                                </li>*/}
                                {/*<li className="modal__item" onClick={removeModalClass}>*/}
                                {/*    <Link to="/refinancing-loan" className={`${activePageName === 'refinancing-loan' ? 'active' : ''}`}>*/}
                                {/*        Рефінансування кредиту*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="modal__item" onClick={removeModalClass}>*/}
                                {/*    <Link to="/study-loan" className={`${activePageName === 'study-loan' ? 'active' : ''}`}>*/}
                                {/*        Кредити на навчання*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="modal__item" onClick={removeModalClass}>*/}
                                {/*    <Link to="/medical-services" className={`${activePageName === 'medical-services' ? 'active' : ''}`}>*/}
                                {/*        Кредит на медичні послуги*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="modal__item" onClick={removeModalClass}>*/}
                                {/*    <Link to="/insurance-services" className={`${activePageName === 'insurance-services' ? 'active' : ''}`}>*/}
                                {/*        Страхові послуги*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                        )}
                    </li>
                    <li className="modal__item" onClick={removeModalClass}>
                        <Link to="/loyalty-program" className={`${activePageName === 'loyalty-program' ? 'active' : ''}`}>Програма лояльності</Link>
                    </li>
                    <li className="modal__item" onClick={removeModalClass}>
                        <Link to="/stock" className={`${activePageName === 'stock' ? 'active' : ''}`}>Акції</Link>
                    </li>
                    <li className="modal__item" onClick={removeModalClass}>
                        <Link to="/faq" className={`${activePageName === 'faq' ? 'active' : ''}`}>Питання та відповіді</Link>
                    </li>
                    <li className="modal__item" onClick={removeModalClass}>
                        <Link to="/about-us" className={`${activePageName === 'about-us' ? 'active' : ''}`}>Про нас</Link>
                    </li>
                    {/* <li className="modal__item" onClick={removeModalClass}>
                        <Link to="/blog" className={`${activePageName === 'blog' ? 'active' : ''}`}>Блог</Link>
                    </li> */}
                </ul>
                <div className="modal__social">
                    <div className="modal__social_item">
                        <img src={instagram} alt="Instagram menu icon" />
                    </div>
                    <div className="modal__social_item">
                        <img src={telegram} alt="telegram menu icon" />
                    </div>
                    <div className="modal__social_item">
                        <img src={facebook} alt="facebook menu icon" />
                    </div>
                    <div className="modal__social_item">
                        <img src={whatsapp} alt="whatsapp menu icon" />
                    </div>
                </div>
            </>} onClose={handleOpenMenuModal} />}
        </header>
    );
};

export default Header;