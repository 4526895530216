import { FormControl, MenuItem, Select, Slider } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import TermsUsingCredit from "../components/TermsUsingCredit/TermsUsingCredit";
import WarningCredit from "../components/WarningCredit/WarningCredit";
import FAQ from "../components/FAQ/FAQ";
import Button from "../components/Common/Button";
import fastestDeliveryIcon from "../assets/fastest-delivery.png";
import cardBlackIcon from "../assets/card_black.png";
import percentIcon from "../assets/percent.png";
import phoneIcon from "../assets/phone.png";
import passportIcon from "../assets/passport.png";
import shieldIcon from "../assets/shield.png";
import carIcon from "../assets/car.png";
import smileHappyIcon from "../assets/smile_happy.png";
import fileInfoAutoCredit from "../files/Інформація_про_послугу_з_надання_кредиту_на_купівлю_автотранспортних.pdf";
import "swiper/css";

const CarLoan = () => {
    const [showWhoCan, setShowWhoCan] = useState(false)

    return (
        <div className="car_loan">
            <div className="top_background__car">
                <div className="container">
                    <Header activePageName="car-loan" />
                    <div className="car_loan__wrapper">
                        <div className="car_loan__left">
                            <div className="title">Автокредит</div>
                            <div className="desc">Онлайн кредит на картку за 5 хвилин</div>
                        </div>
                        <div className="car_loan__calculate">
                            <div className="car_loan__calculate_left loan_calculate">
                                <div className="title">Автокредит</div>
                                <div className="car_loan__calculate_subtitle">Онлайн кредит на картку за 5 хвилин</div>
                                <div className="loan_calculate__slider">
                                    <div className="loan_calculate__slider_title">Вартість автомобіля</div>
                                    <Slider
                                        defaultValue={400000}
                                        aria-label="Default"
                                        valueLabelDisplay="auto"
                                        min={400000}
                                        max={6000000}
                                    />
                                    <div className="loan_calculate__slider_block">
                                        <div className="loan_calculate__slider_desc">400000 ₴</div>
                                        <div className="loan_calculate__slider_desc">6000000 ₴</div>
                                    </div>
                                </div>
                                <div className="loan_calculate__slider">
                                    <div className="loan_calculate__slider_title">Cтрок кредитування, місяців</div>
                                    <Slider
                                        defaultValue={15}
                                        aria-label="Default"
                                        valueLabelDisplay="auto"
                                        min={3}
                                        max={36}
                                    />
                                    <div className="loan_calculate__slider_block">
                                        <div className="loan_calculate__slider_desc">3 міс.</div>
                                        <div className="loan_calculate__slider_desc">36 міс.</div>
                                    </div>
                                </div>
                                <div className="loan_calculate__block_select">
                                    <div>
                                        <div className="loan_calculate__block_select_title">Об’єм двигуна</div>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="car-loan-label-1"
                                                id="car-loan-1"
                                                // value={age}
                                                // onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="less_1600">до 1600</MenuItem>
                                                <MenuItem value="1601-2000">1601-2000</MenuItem>
                                                <MenuItem value="2001-3000">2001-3000</MenuItem>
                                                <MenuItem value="more_3001">більше 3001</MenuItem>
                                                <MenuItem value="electric">електродвигун</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <div className="loan_calculate__block_select_title">Рік випуску автомобіля</div>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="car-loan-label-2"
                                                id="car-loan-2"
                                                // value={age}
                                                // onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value={1900}>1900</MenuItem>
                                                <MenuItem value={1901}>1901</MenuItem>
                                                <MenuItem value={1902}>1902</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <div className="loan_calculate__block_select_title">Тип пального</div>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="car-loan-label-3"
                                                id="car-loan-3"
                                                // value={age}
                                                // onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="petrol">Бензин</MenuItem>
                                                <MenuItem value="diesel">Дизель</MenuItem>
                                                <MenuItem value="electric">Електро</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <div className="loan_calculate__block_select_title">Майбутнє місце реєстрації автомобіля</div>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="car-loan-label-4"
                                                id="car-loan-4"
                                                // value={age}
                                                // onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value="Київ">Київ</MenuItem>
                                                <MenuItem value="Дніпро">Дніпро</MenuItem>
                                                <MenuItem value="Львів">Львів</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="car_loan__calculate_info car_loan__calculate_info_table">
                                    <Link to={fileInfoAutoCredit} className="car_loan__calculate_info__link" target="_blank" download>
                                        Інформація про послугу з надання кредиту на купівлю автотранспорту.
                                    </Link>
                                </div>
                            </div>
                            <div className="car_loan__calculate_right loan_calculate__right">
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Розмір авансового внеску, від:</div>
                                    <div className="loan_calculate__right__number">20%</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Річна процентна ставка по кредиту, від:</div>
                                    <div className="loan_calculate__right__number">25%</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Комісія кредитора разова, %:</div>
                                    <div className="loan_calculate__right__number">20%</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Комісія кредитора регулярна, %:</div>
                                    <div className="loan_calculate__right__number">20%</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Вартість реєстрації автомобіля:</div>
                                    <div className="loan_calculate__right__number">587 грн</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Вартість КАСКО, від:</div>
                                    <div className="loan_calculate__right__number">39878 грн</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Нотаріальні послуги, від:</div>
                                    <div className="loan_calculate__right__number">3700 грн</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__desc">Якщо строк кредиту перевищує 1 рік, то цей платіж стягується кожен рік існування кредиту.</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">ОСЦПВ, від:</div>
                                    <div className="loan_calculate__right__number">1484 грн</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__desc">Якщо строк кредиту перевищує 1 рік, то цей платіж стягується кожен рік існування кредиту</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Вартість встановлення GPS та моніторингу автомобіля, грн</div>
                                    <div className="loan_calculate__right__number">5000 грн</div>
                                </div>
                                <div className="loan_calculate__right__block">
                                    <div className="loan_calculate__right__title">Загальна вартість кредита, грн.</div>
                                    <div className="loan_calculate__right__number">5000 грн</div>
                                </div>
                                <button className="btn btn__pink">Оформити кредит</button>
                            </div>
                            <div className="car_loan__calculate_info car_loan__calculate_info_mobile">
                                <Link to={fileInfoAutoCredit} className="car_loan__calculate_info__link" target="_blank" download>
                                    Інформація про послугу з надання кредиту на купівлю автотранспорту.
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="get_loan car_loan__benefit">
                <div className="container">
                    <div className="get_loan__block">
                        <div className="get_loan__title">Автокредит – це зручно</div>
                        <div className="get_loan__desc">
                            Щоб взяти кредит онлайн на картку, ми не ставимо жорстких обмежень та умов і готові видати Вам кредит, якщо
                        </div>
                    </div>
                    <div className="get_loan__list">
                        <div className="get_loan__item">
                            <img src={fastestDeliveryIcon} alt="icon 1" />
                            <div className="get_loan__item_text">Прості умови кредиту</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={percentIcon} alt="icon 2" />
                            <div className="get_loan__item_text">Можливе дострокове<br/> погашення</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={passportIcon} alt="icon 3" />
                            <div className="get_loan__item_text">Мінімум<br/> документів</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={carIcon} alt="icon 4" />
                            <div className="get_loan__item_text">Перевірка авто</div>
                        </div>
                        <div className="get_loan__item">
                            <img src={shieldIcon} alt="icon 5" />
                            <div className="get_loan__item_text">Привабливі умови</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="car_loan_who_can">
                <div className="container">
                    <div className="car_loan_who_can__title">Хто може отримати кредит</div>
                    <div className={`car_loan_who_can__block ${showWhoCan ? 'active' : ''}`}>
                        <div className="car_loan_who_can__desc">
                            Мінімальна сума споживчого кредиту становить - 200 000,00 грн, максимальна сума/ліміт споживчого кредиту - 3 000 000,00 грн.
                        </div>
                        <div className="car_loan_who_can__desc">
                            Відсотки нараховуються за кожен фактичний день користування кредитом. Кредит можна погасити як в день закінчення строку кредиту, так і раніше строку. За дострокове погашення кредиту не буде застосовано жодних штрафних санкцій - ви просто сплачуєте відсотки за дні фактичного користування.
                        </div>
                        <div className="car_loan_who_can__desc">
                            Споживач має можливість отримати грошові кошти у кредит, на споживчі цілі, без застави за фіксованою процентною ставкою. Сума кредиту, строк, вартість послуги та інші умови узгоджуються сторонами шляхом вибору клієнтом запропонованих доступних параметрів позики та закріплюються договором, укладеним в електронній формі відповідно до Закону України «Про електронну комерцію».
                        </div>
                        <div className="car_loan_who_can__desc">
                            Надання коштів у кредит не передбачає надання Товариством додаткових фінансових послуг Споживачам.
                        </div>
                    </div>
                    <div className="car_loan_who_can__block_btn">
                        <Button
                            onClick={() => setShowWhoCan(prev => !prev)}
                            pink
                        >
                            {showWhoCan ? 'Приховати' : 'Читати повністю'}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="car_loan_how_get_loan">
                <div className="container">
                    <div className="car_loan_how_get_loan__title title">Як отримати кредит</div>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        breakpoints={{
                            500: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                        }}
                        className="car_loan_how_get_loan__swiper"
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>
                            <div className="get_loan__item car_loan_how_get_loan__item">
                                <div className="get_loan__item_img">
                                    <img src={carIcon} alt="icon 1" />
                                </div>
                                <div className="get_loan__item_text">Вибрати автомобіль</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item car_loan_how_get_loan__item">
                                <div className="get_loan__item_img">
                                    <img src={phoneIcon} alt="icon 2" />
                                </div>
                                <div className="get_loan__item_text">Подати on-line заявку на кредит</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item car_loan_how_get_loan__item">
                                <div className="get_loan__item_img">
                                    <img src={passportIcon} alt="icon 3" />
                                </div>
                                <div className="get_loan__item_text">Оформити кредит</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item car_loan_how_get_loan__item">
                                <div className="get_loan__item_img">
                                    <img src={cardBlackIcon} alt="icon 4" />
                                </div>
                                <div className="get_loan__item_text">Зробити авансовий внесок</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item car_loan_how_get_loan__item">
                                <div className="get_loan__item_img">
                                    <img src={carIcon} alt="icon 5" />
                                </div>
                                <div className="get_loan__item_text">Оформити автомобіль</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="get_loan__item car_loan_how_get_loan__item">
                                <div className="get_loan__item_img">
                                    <img src={smileHappyIcon} alt="icon 6" />
                                </div>
                                <div className="get_loan__item_text">Насолоджуватись керуванням</div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="car_loan_how_get_loan__list get_loan__list">
                        <div className="get_loan__item car_loan_how_get_loan__item">
                            <div className="get_loan__item_img">
                                <img src={carIcon} alt="icon 1" />
                            </div>
                            <div className="get_loan__item_text">Вибрати<br/> автомобіль</div>
                        </div>
                        <div className="get_loan__item car_loan_how_get_loan__item">
                            <div className="get_loan__item_img">
                                <img src={phoneIcon} alt="icon 2" />
                            </div>
                            <div className="get_loan__item_text">Подати on-line<br/> заявку на кредит</div>
                        </div>
                        <div className="get_loan__item car_loan_how_get_loan__item">
                            <div className="get_loan__item_img">
                                <img src={passportIcon} alt="icon 3" />
                            </div>
                            <div className="get_loan__item_text">Оформити<br/> кредит</div>
                        </div>
                        <div className="get_loan__item car_loan_how_get_loan__item">
                            <div className="get_loan__item_img">
                                <img src={cardBlackIcon} alt="icon 4" />
                            </div>
                            <div className="get_loan__item_text">Зробити<br/> авансовий внесок</div>
                        </div>
                        <div className="get_loan__item car_loan_how_get_loan__item">
                            <div className="get_loan__item_img">
                                <img src={carIcon} alt="icon 5" />
                            </div>
                            <div className="get_loan__item_text">Оформити<br/> автомобіль</div>
                        </div>
                        <div className="get_loan__item car_loan_how_get_loan__item">
                            <div className="get_loan__item_img">
                                <img src={smileHappyIcon} alt="icon 6" />
                            </div>
                            <div className="get_loan__item_text">Насолоджуватись<br/> керуванням</div>
                        </div>
                    </div>
                    <div className="car_loan_how_get_loan__block_btn">
                        <button className="btn btn__pink">
                            <Link
                                to="https://cab.groshi247.com/signup"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Оформити кредит
                            </Link>
                        </button>
                    </div>
                </div>
            </div>

            <WarningCredit />

            <TermsUsingCredit
                content={(
                    <>
                        Мінімальна сума споживчого кредиту становить - 200 000,00 грн, максимальна сума/ліміт споживчого кредиту - 3 000 000,00 грн. в залежності від обраного кредитного продукту.
                        <br/>
                        <br/>
                        Відсотки нараховуються за кожен фактичний день користування кредитом. Кредит можна погасити як в день, визначений в графіку погашення кредиту, так і раніше строку. За дострокове погашення кредиту не буде застосовано жодних штрафних санкцій - Ви просто сплачуєте відсотки за дні фактичного користування кредитом.
                        <br/>
                        <br/>
                        Споживач має можливість отримати грошові кошти у кредит, на споживчі цілі, під заставу або поруку третьої особи за фіксованою процентною ставкою. Сума кредиту, строк, вартість послуги та інші умови узгоджуються сторонами шляхом вибору клієнтом запропонованих доступних параметрів позики та закріплюються договором, укладеним в електронній формі відповідно до Закону України «Про електронну комерцію».
                        <br/>
                        <br/>
                        Надання коштів в кредит не передбачає надання Товариством додаткових фінансових послуг Споживачам.
                        <br/>
                        <br/>
                        Надання коштів в кредит не передбачає сплати податків і зборів за рахунок фізичної особи в результаті отримання фінансової послуги.
                        <br/>
                        <br/>
                        У випадку оформлення застави по споживчому кредиту клієнт може понести додаткові витрати на нотаріальне оформлення договору застави та/або страхування предмету застави, моніторинг стану предмета застави протягом часу знаходження його в заставі. Величина таких додаткових витрат не визначається Товариством і залежить від вартості предмета, оформленого в заставу, і страхового тарифу на цей предмет застави, який визначається обраною Споживачем страховою компанією, вартості нотаріальних послуг кожного конкретного нотаріуса.
                        <br/>
                        <br/>
                        Фінансова послуга надається Споживачам на умовах, визначених відповідним договором про споживче кредитування. У разі надходження на рахунок Товариства грошових коштів від Споживача в достатньому обсязі для погашення його кредиту, зобов’язання Споживача вважаються належно виконаними і в такому випадку відповідний договір про споживчий кредит припиняє свою дію з дати зарахування грошових коштів на рахунок Товариства.
                        <br/>
                        <br/>
                        Протягом 14 календарних днів з дня укладення відповідного договору про споживчий кредит Споживач має право відмовитися від договору без пояснення причин, у тому числі в разі отримання ним грошових коштів.
                        <br/>
                        <br/>
                        У такому випадку Споживач зобов’язаний повернути суму отриманого кредиту та нараховані проценти за користування кредитом відповідно до кількості днів користування. Про намір відмовитися від договору про споживчий кредит Споживач повинен повідомити Товариство у письмовій формі (у паперовому або електронному вигляді з накладенням електронних підписів, електронних цифрових підписів, інших аналогів власноручних підписів (печаток) сторін у порядку, визначеному законодавством). Протягом семи календарних днів з дати подання письмового повідомлення про відмову від договору про споживчий кредит, Споживач зобов’язаний повернути грошові кошти, одержані згідно з договорм про споживчий кредит, та сплатити проценти за період з дня одержання коштів до дня їх повернення за процентною ставкою, встановленою відповідним договором про споживчий кредит.
                        <br/>
                        <br/>
                        Розмір фіксованої процентної ставки, встановлений відповідним договором про споживчий кредит, не може бути збільшений без письмової згоди Споживача.
                        <br/>
                        <br/>
                        У випадку дострокового повернення кредиту, Споживач зобов’язується повернути Товариству суму кредиту та сплатити проценти за фактичну кількість днів користування кредитом. Плата за факт дострокового повернення кредиту відсутня.
                        <br/>
                        <br/>
                        За ініціативою Споживача договір про споживчий кредит може бути розірваний в судовому порядку, за умов виконання ним зобов’язань за відповідним договором про споживчий кредит.
                        <br/>
                        <br/>
                        Для вирішення спірних питань у досудовому порядку, Споживачі мають змогу звернутися безпосередньо до Товариства, викласти в чіткій і зрозумілій формі свої зауваження, які розглядаються Товариством у порядку та у строки, встановлені Законом України «Про звернення громадян». Крім цього, споживачі мають змогу звернутися до суду за захистом своїх прав та до Національного банку України, веб-сторінка: <a href="https://bank.gov.ua" target="_blank">bank.gov.ua</a>
                        <br/>
                        <br/>
                        Гарантійний фонд в Товариство з обмеженою відповідальністю «Гроші 247» і компенсаційні схеми відсутні.
                        <br/>
                        <br/>
                        <div className="terms_using_credit__block_info">
                            Товариство з обмеженою відповідальністю «Гроші 247» попереджає Споживача про те, що:
                            <br/>
                            <br/>
                            В разі невиконання обовʼязків згідно з договором про споживчий кредит до Споживача можуть бути застосовані штраф/пеня, а також інші платежі, передбачені договором про споживчий кредит.
                            <br/>
                            <br/>
                            Порушення виконання зобов’язання щодо повернення кредиту у передбачені договором про споживчий кредит строки може вплинути на кредитну історію та ускладнити отримання кредиту надалі;
                            <br/>
                            <br/>
                            Товариству з обмеженою відповідальністю «Гроші 247» забороняється вимагати від Споживача придбання будь-яких товарів чи послуг від Товариства з обмеженою відповідальністю «Гроші 247» або спорідненої чи повʼязаної з нею особи як обовʼязкову умову надання кредиту;
                            <br/>
                            <br/>
                            Для прийняття усвідомленого рішення щодо отримання кредиту на запропонованих умовах Споживач має право розглянути альтернативні різновиди споживчих кредитів та фінансових установ;
                            <br/>
                            <br/>
                            Товариство з обмеженою відповідальністю «Гроші 247» має право вносити зміни до укладених зі Споживачами договорів про споживчий кредит тільки за взаємною згодою сторін у спосіб та на умовах, визначених договором про споживчий кредит;
                            <br/>
                            <br/>
                            Споживач має право відмовитися від отримання рекламних матеріалів Товариства засобами дистанційних каналів комунікації;
                            <br/>
                            <br/>
                            Можливі витрати на сплату Споживачем платежів за користування споживчим кредитом в залежності від обраного Споживачем способу сплати;
                            <br/>
                            <br/>
                            Споживач має право на продовження (лонгації, пролонгації) строку погашення кредиту (строку виконання грошового зобов’язання)/строку кредитування/строку дії договору про кредит. Ці дії здійснюються в Особистому кабінеті Споживача, без змін або зі зміною умов попередньо укладеного договору в бік погіршення для Споживача із зазначенням переліку та цифрового значення умов, що підлягають зміні та залежить від виду договору про споживче кредитування;
                            <br/>
                            <br/>
                            Споживач повинен негайно інформувати Товариство з обмеженою відповідальністю «Гроші 247» про несанкціонований доступ або зміну інформації про себе в системах дистанційного обслуговування Товариства з обмеженою відповідальністю «Гроші 247».
                            <br/>
                            <br/>
                            Особи, які належать до захищених категорій осіб, мають право повідомити Товариство про свій статус, використовуючи будь-який канал зв’язку, зазначений на офіційному сайті Товариства.
                        </div>
                        <br/>
                        <br/>
                        Реквізити органів, які здійснюють державне регулювання ринків фінансових послуг та з питань захисту прав споживачів:
                        <br/>
                        <br/>
                        Національний банк України.
                        <br/>
                        <br/>
                        Телефон: 0 800 505 240 (у разі, якщо Ви не додзвонилися до оператора і всі лінії були зайняті або Ви телефонуєте в неробочий день та час, – залиште Ваш номер телефону на автовідповідач і ми Вам зателефонуємо).
                        <br/>
                        <br/>
                        Електронна пошта: nbu@bank.gov.ua (заповніть і надішліть форму та за потреби додайте супровідні матеріали. Але розмір вкладення – не більше 10 МБ).
                        <br/>
                        <br/>
                        Веб-сторінка: <a href="https://bank.gov.ua" target="_blank">bank.gov.ua</a>
                        <br/>
                        <br/>
                        Для листування: вул. Інститутська, 9, м. Київ, 01601.
                        <br/>
                        <br/>
                        Для подання письмових звернень громадян: вул. Інститутська, 11-б, м. Київ, 01601.
                        <br/>
                        <br/>
                        Державна служба України з питань безпеки харчових продуктів та захисту споживачів
                        <br/>
                        <br/>
                        Адреса: 01001, м. Київ, вул. Бориса Грінченка, 1, тел. +38 (044) 279 12 70
                        <br />
                        <br />
                        <span style={{ textAlign: 'center' }}>ВІДСОТКИ:</span>
                        <br />
                        <br />
                        В Groshi247 діє 2 типи кредитних програм: для нових клієнтів та для тих, хто вже брав у нас кредит і успішно його повернув.
                        <br />
                        <br />
                        Програма для нових клієнтів дозволяє взяти кредит від 200 000 до 3 000 000 грн. на строк до 30 днів, в залежності від обрано фінансового продукту. З детальними умовами кожного продукту можна ознайомитись в паспортах відповідних продуктів, розміщених на офіційному сайті Товариства.
                        <br />
                        <br />
                        Відсотки такі:
                        <br />
                        <br />
                        - 1.9% від суми кредита в день протягом визначеного договором строку користування кредитом.
                        <br />
                        <br />
                        - 3,8 % від суми кредита в день за кожен день користування кредитом після завершення іизначеного договором строку користування кредитом.
                        <br />
                        <br />
                        Розберемо на прикладі:
                        <br />
                        <br />
                        Петро хоче взяти кредит в розмірі 3000 грн на 4 дні.
                        <br />
                        <br />
                        Кожний день користування таким кредитом коштуватиме йому 3000*1,9%=57 грн.
                        <br />
                        <br />
                        Загалом наприкінці терміну Петро сплатить 3000 грн. + 57*4 грн. = 3228 грн.
                        <br />
                        <br />
                        Для повторних позичальників доступні більш лояльні умови та більші суми позик.
                    </>
                )}
            />

            <FAQ />

            <Footer />
        </div>
    );
};

export default CarLoan;