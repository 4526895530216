import React, { useState } from "react";
import "./PushNotificationModal.scss";
import { Button } from "@mui/material";
import { LANDING_URL } from "../../const/api";

const PushNotificationModal = () => {
    const [showNotification, setShowNotification] = useState(true);

    return (
        <div
            className="push_notification"
            style={{ display: showNotification ? "block" : "none" }}
        >
            <div className="push_notification_prompt_message">
                <div className="push_notification_prompt_message_text">
                    <div className="push_prompt_text">
                        Даруємо знижку
                        <b> 50% </b>
                        🎁 за промокодом:
                        <b> N50</b>
                    </div>
                </div>
                <div className="push_notification__buttons">
                    <Button
                        variant="outlined"
                        size="small"
                        className="btn__popup empty"
                        onClick={() => setShowNotification(false)}
                    >
                        Ні, дякую
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn__popup"
                        href={`${LANDING_URL}/`}
                    >
                        Хочу отримати!
                    </Button>
                </div>
                <span className="close_prompt" onClick={() => setShowNotification(false)} />
            </div>
        </div>
    );
};

export default PushNotificationModal;